import React, { useEffect } from 'react'
import _get from 'lodash/get'
import Fade from 'react-reveal/Fade'
import Layout from '../components/common/Layout'
import Page from '../components/world-of-data/Page'

export const SingleCountryPage = ({ pageContext }) => {
  const title = pageContext.title
  const category = pageContext.category
  const description = pageContext.description
  const mapURL = pageContext.mapURL
  const body = pageContext.body

  return (
    <Layout
      title={`${title} Map`}
      meta={{
        description:
          'Explore the world of data with Atlas. Discover the most interesting spatial data and maps from around the world.',
        title: `${title} Map | Atlas`,
        absoluteImageUrl: 'https://www.atlas.co/images/world-of-data.png',
        type: 'website'
      }}
    >
      <main>
        <Page
          title={title}
          category={category}
          description={description}
          mapURL={mapURL}
          body={body}
        />
      </main>
    </Layout>
  )
}

export default SingleCountryPage
