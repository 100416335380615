import React, { useState, useEffect } from 'react'
import styles from './Page.module.scss'
import Content from '../singlePost/Content'
import { Link } from 'gatsby'
import _ from 'lodash'
import ReactCountryFlag from 'react-country-flag'
import countries from 'i18n-iso-countries'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const Page = ({ title, category, description, mapURL, body }) => {
  const countryCode = countries.getAlpha2Code(title, 'en')

  console.log(body)

  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div>
            <div>
              <div className={styles.headerContent}>
                <div className={styles.heading}>
                  <div className={styles.breadcrumb}>
                    <Link to={`/world-of-data/`}>Explore</Link> /{' '}
                    <Link to={`/explore/${category}/`}>
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </Link>{' '}
                    /{' '}
                    <Link
                      to={`/explore/${category}/${title
                        .toLowerCase()
                        .replace(/\s+/g, '-')}/`}
                    >
                      {title}
                    </Link>
                  </div>
                </div>
              </div>
              <h1>
                <ReactCountryFlag
                  countryCode={countryCode}
                  svg
                  aria-label={title}
                  style={{
                    width: '32px',
                    height: '24px',
                    borderRadius: '5px',
                    marginRight: '0.5rem',
                    marginTop: '-0.5rem'
                  }}
                />
                {title + ' Map'}
              </h1>
              <div className={styles.layout}>
                <div className={styles.leftContainer}>
                  <p>{description}</p>
                  <iframe
                    src={mapURL}
                    frameborder="0"
                    width="100%"
                    height="600"
                    allowFullScreen
                    style={{
                      maxWidth: '100%',
                      border: '1px solid #EAEAEA',
                      borderRadius: '4px',
                      marginTop: '2rem',
                      marginBottom: '2rem'
                    }}
                  ></iframe>
                  <article itemScope itemType="http://schema.org/BlogPosting">
                    <Content source={body} />
                  </article>
                </div>
                <div className={styles.rightContainer}>
                  <p>Info powered by Atlas</p>
                  <div className={styles.linkContainer}>
                    <a
                      href={mapURL?.replace(/\/embeds\//g, '/projects/')}
                      target="_blank"
                    >
                      <div className={styles.linkItem}>
                        <img src="/icons/map.svg" alt="Map" />
                        <span>Open interactive map</span>
                      </div>
                    </a>
                  </div>
                  <div className={styles.linkContainer}>
                    <a href={`https://app.atlas.co/login`} target="_blank">
                      <div className={styles.linkItem}>
                        <img src="/icons/screen.svg" alt="Screen" />
                        <span>Explore Atlas</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Page
